var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.settings)?_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('BaseCheckboxLabel',{staticClass:"pl-3 mb-3",attrs:{"hide-details":""},on:{"input":_vm.handleConfirmBeforeOpenChange},model:{value:(_vm.confirmBeforeOpen),callback:function ($$v) {_vm.confirmBeforeOpen=$$v},expression:"confirmBeforeOpen"}},[_vm._v(" "+_vm._s(_vm.$t("board.confirm_before_open"))+" ")])],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","justify":"center"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"nudge-bottom":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('BaseButtonIcon',_vm._g(_vm._b({attrs:{"large":""},on:{"click":_vm.applyBackgroundColor}},'BaseButtonIcon',attrs,false),on),[_c('Icon',{attrs:{"tooltip":"Background color"}},[_vm._v(_vm._s(_vm.svgFile))]),_c('div',{staticClass:"color-preview",style:({ 'background-color': _vm.backgroundColor })})],1)]}}],null,false,3387955075)},[_c('v-color-picker',{attrs:{"mode":"hexa"},on:{"input":_vm.applyBackgroundColor},model:{value:(_vm.backgroundColor),callback:function ($$v) {_vm.backgroundColor=$$v},expression:"backgroundColor"}})],1),_c('CropImage',{on:{"applyBackgroundImage":_vm.applyBackgroundImage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var selectFile = ref.selectFile;
return [_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"large":""},on:{"click":selectFile}},[_c('Icon',{attrs:{"tooltip":"Background image"}},[_vm._v(" "+_vm._s(_vm.svgFileImage)+" ")])],1)]}}],null,false,2279033697)}),_c('label',{staticClass:"text-body-1",staticStyle:{"left":"0px","right":"auto","top":"3px","position":"relative"}},[_vm._v(" "+_vm._s(_vm.$t("board.set_background"))+" ")])],1)],1),_c('v-divider'),_vm._l((_vm.colorsToChange),function(color){return _c('v-row',{key:color},[_c('v-col',{staticClass:"color"},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"nudge-bottom":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('BaseButtonIcon',_vm._g(_vm._b({attrs:{"large":""}},'BaseButtonIcon',attrs,false),on),[_c('Icon',{attrs:{"tooltip":(color + " color")}},[_vm._v(_vm._s(_vm.svgFile))]),_c('div',{staticClass:"color-preview",style:({ 'background-color': _vm.colors[color] })})],1)]}}],null,true)},[_c('v-color-picker',{attrs:{"mode":"hexa"},on:{"input":function($event){return _vm.colorChange($event, color)}},model:{value:(_vm.colors[color]),callback:function ($$v) {_vm.$set(_vm.colors, color, $$v)},expression:"colors[color]"}})],1),_c('label',{staticClass:"text-body-1",staticStyle:{"left":"0","right":"auto","top":"3px","position":"relative"}},[_vm._v(" Change "+_vm._s(color)+" color ")])],1)],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('BaseButton',{staticClass:"mb-3",on:{"click":_vm.restoreDefaultColors}},[_vm._v(" Restore default colors ")])],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('BaseSelect',{staticClass:"mt-3",attrs:{"value":_vm.fontFamily,"items":_vm.DEFAULT_FONT_FAMILY_MAP,"label":"Change global font","outlined":""},on:{"change":_vm.setGlobalFont},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var active = ref.active;
var item = ref.item;
return [(item === _vm.EMPTY_FONT_FAMILY)?_c('span',[_vm._v(_vm._s(item))]):_c('span',{style:({ 'font-family': item })},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,1899310628)})],1)],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }