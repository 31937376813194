
















































































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import CropImage from "@/components/Shared/CropImage.vue";
import {
  DEFAULT_FONT_FAMILY_MAP,
  EMPTY_FONT_FAMILY,
  getGlobalFont
} from "@/utils/font";
// @ts-ignore
import colorcolor from "colorcolor";
import { mdiFile, mdiFileImage } from "@mdi/js";
import { Board } from "@/store/types";
import { getCurrentThemeColors } from "@/helpers/vuetifyHelpers";
import { BACKGROUND, baseLightColors } from "@/utils/color";

export default Vue.extend({
  name: "BoardSettingsForm",
  components: { CropImage },
  data() {
    return {
      colorsToChange: [
        `${this.$t("board.color_primary")}`,
        `${this.$t("board.color_secondary")}`,
        `${this.$t("board.color_accent")}`
      ],
      EMPTY_FONT_FAMILY,
      DEFAULT_FONT_FAMILY_MAP: Object.keys(DEFAULT_FONT_FAMILY_MAP).map(
        el => DEFAULT_FONT_FAMILY_MAP[el]
      ),
      colors: { ...getCurrentThemeColors() },
      colors2: getCurrentThemeColors(),
      settings: null as Board | null,
      backgroundColor: BACKGROUND,
      background: "",
      fontFamily: "",
      svgFile: mdiFile,
      svgFileImage: mdiFileImage
    };
  },
  computed: {
    ...mapState("boards", ["currentBoard"]),
    boardId(): string {
      return this.currentBoard?.id;
    },
    confirmBeforeOpen: {
      get(): boolean {
        return this.settings?.confirmBeforeOpen || false;
      },
      set(newVal: boolean) {
        if (this.settings) this.settings.confirmBeforeOpen = newVal;
      }
    }
  },
  mounted() {
    const bg = (document.getElementsByClassName("div--board")[0] as HTMLElement)
      ?.style?.background;
    if (bg) {
      this.background = bg;
      if (!this.background.includes("base64")) {
        this.backgroundColor = colorcolor(bg, "hexa");
      }
    }

    this.fontFamily = getGlobalFont() || "";
    this.settings = { ...this.currentBoard };
  },
  methods: {
    ...mapActions("boards", ["saveBoard"]),
    colorChange() {
      this.$emit("onOptionsUpdate", {
        colors: this.colors
      });
    },
    applyBackgroundColor() {
      this.background = this.backgroundColor;
      const opts = {
        background: this.backgroundColor
      };
      this.$emit("onOptionsUpdate", opts);
    },
    restoreDefaultColors() {
      this.colors = { ...baseLightColors };
      this.$emit("onOptionsUpdate", {
        colors: {
          ...baseLightColors
        }
      });
    },
    applyBackgroundImage(dataUrl: string) {
      this.background = `url(${dataUrl}) no-repeat center center`;
      const opts = {
        background: `url(${dataUrl}) no-repeat center center`
      };
      this.$emit("onOptionsUpdate", opts);
    },
    setGlobalFont(f: string) {
      this.fontFamily = f === "Default" ? "Inter" : f;

      this.$emit("onOptionsUpdate", {
        fontFamily: this.fontFamily
      });
    },
    handleConfirmBeforeOpenChange(confirmBeforeOpen: boolean) {
      this.$emit("onSettingsUpdate", {
        confirmBeforeOpen
      });
    }
  },
  validations() {
    return {
      settings: {}
    };
  }
});
